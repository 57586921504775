import React, { useState, createContext } from "react";

const ThemeContext = createContext();

export function ThemeProvider({ children }) {
  const [colors, setColors] = useState({
    primaryOne: "#00ffff",
    primaryOneDark: "#1b1464",
    primaryTwo: "#ff00d5",
    primaryTwoDark: "#3b3aee",
    white: "#ffffff",
    black: "black",
    backgroundTop: "#cfcfcf",
    backgroundBottom: "#ffffff",
    sectionBackground: "#ffffff",
    boxShadowColor: "black",
  });

  const setOneColor = (color) => {
    setColors((prevState) => {
      return { ...prevState, ...color };
    });
  };

  return (
    <ThemeContext.Provider value={[colors, setOneColor]}>
      {children}
    </ThemeContext.Provider>
  );
}

export default ThemeContext;
