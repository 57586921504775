import React, { useContext } from "react";
import styled, { createGlobalStyle } from "styled-components";

import ThemeContext from "../context/ThemeContext";

import "normalize.css";
import "./css/typography.css";

const GlobalStyle = createGlobalStyle`
  :root {
    --primary-one: ${(props) => props.colors.primaryOne};
    --primary-one-dark: ${(props) => props.colors.primaryOneDark};
    --primary-two: ${(props) => props.colors.primaryTwo};
    --primary-two-dark: ${(props) => props.colors.primaryTwoDark};
    --white: ${(props) => props.colors.white};
    --black: ${(props) => props.colors.black};
    --background-top: ${(props) => props.colors.backgroundTop};
    --background-bottom: ${(props) => props.colors.backgroundBottom};
    --section-background: ${(props) => props.colors.sectionBackground};
    --box-shadow-color: ${(props) => props.colors.boxShadowColor};
  }

  html {
    font-size: 62.5%;
  }

  body {
    font-family: "Gilroy";
  }
`;

const Layout = ({ children }) => {
  const [colors] = useContext(ThemeContext);

  return (
    <>
      <GlobalStyle colors={colors} />
      {children}
    </>
  );
};

export default Layout;
