import React from "react";
import Layout from "./src/components/Layout";
import { ThemeProvider } from "./src/context/ThemeContext";

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

export const wrapRootElement = ({ element }) => {
  return <ThemeProvider>{element}</ThemeProvider>;
};
